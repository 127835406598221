import React from 'react';
import { supabase } from '@/lib/supabase';

export function GoogleSignIn() {
  const handleGoogleSignIn = async () => {
    try {
      // Get the environment-specific redirect URL
      const redirectUrl = import.meta.env.VITE_REDIRECT_URL;
      console.log('OAuth Redirect URL:', `${redirectUrl}/auth/callback`); // Debug log
      
      const { data, error: signInError } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${redirectUrl}/auth/callback`,
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
        },
      });

      if (signInError) {
        console.error('OAuth Error:', signInError); // Debug log
        throw signInError;
      }

      // After signing in, retrieve the user's UUID
      // const userId = user?.id; // Get the user's UUID

      // // Check if the user has an associated company
      // const { data, error: fetchError } = await supabase
      //   .from('companies') // Replace 'companies' with your actual table name
      //   .select('*')
      //   .eq('user_id', userId) // Assuming 'user_id' is the foreign key in the companies table
      //   .single(); // Use .single() to get a single record

      // if (fetchError) throw fetchError;

      // if (data) {
      //   console.log('User has an associated company:', data);
      //   // Handle the case where the user has an associated company
      // } else {
      //   console.log('User does not have an associated company.');
      //   // Handle the case where the user does not have an associated company
      // }
      // });

      // if (error) throw error;
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  return (
    <button
      onClick={handleGoogleSignIn}
      className="w-full flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
    >
      <svg className="h-5 w-5" viewBox="0 0 24 24">
        <path
          d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
          fill="#4285F4"
        />
        <path
          d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
          fill="#34A853"
        />
        <path
          d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
          fill="#FBBC05"
        />
        <path
          d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
          fill="#EA4335"
        />
      </svg>
      Continue with Google
    </button>
  );
}
